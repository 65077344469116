<template>
  <section class="white">
    <div
      class="d-flex justify-space-between align-center pr-14 pl-14 pt-8 pb-8"
    >
      <h1 class="primary--text text-h6 font-weight-black">{{ getTitle() }}</h1>
      <v-btn
        class="pl-6 pr-6"
        outlined
        color="primary"
        @click="$emit('changeComponent', 'ClientsTable')"
      >
        REGRESAR
      </v-btn>
    </div>
    <v-divider></v-divider>
    <div class="pr-14 pl-14 mt-8 mb-8">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">Nombre</p>
              <v-text-field
                v-model="client.name"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el nombre del cliente"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                Apellido paterno
              </p>
              <v-text-field
                v-model="client.lastNamePather"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el apellido paterno"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                Apellido materno
              </p>
              <v-text-field
                v-model="client.lastNameMother"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el apellido materno"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">Calle</p>
              <v-text-field
                v-model="client.street"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el nombre de la calle"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="2" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                No. Exterior
              </p>
              <v-text-field
                v-model.number="client.outdoorNumber"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="No. Exterior"
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="2" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                No. Interior
              </p>
              <v-text-field
                v-model.number="client.interiorNumber"
                class="rounded-0"
                dense
                height="50"
                placeholder="No. Interior"
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">C.P.</p>
              <v-text-field
                v-model.number="client.zip"
                :rules="zipValidation"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escribe el código postal"
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">Colonia</p>
              <v-text-field
                v-model="client.suburb"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escribe el nombre de la colonia"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">Municipio</p>
              <v-text-field
                v-model="client.city"
                :rules="requiredText"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escribe el nombre del municipio"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                No. de Teléfono
              </p>
              <v-text-field
                v-model.number="client.movilNumber"
                :rules="numberPhone"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escribe el número de teléfono"
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col v-if="isEdit" cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                Tipo de Cliente
              </p>
              <v-select
                :items="items"
                :rules="requiredText"
                outlined
                v-model="client.userType"
                placeholder="Seleccione el tipo de cliente"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <div>
              <p class="text-subtitle-2 font-weight-medium mb-2">
                Tag
              </p>
              <v-textarea
                solo
                no-resize
                name="input-7-4"
                label="Escriba la información adicional del plan"
                v-model="client.tag"
              ></v-textarea>
            </div>
          </v-col>
          <v-col cols="12" class="pt-0 d-flex flex-row-reverse">
            <v-btn color="primary" depressed @click="stateManager" :loading="loading">{{
              getTextButton()
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="pl-14 pb-16">
      <v-img class="img--container" src="@/assets/icons/client-aqua.svg">
      </v-img>
    </div>
  </section>
</template>

<script>
// Domain
import Clients from "../../domain/Clients";

export default {
  name: "ClientNew",
  mounted() {
    if (this.isEdit) {
      this.client = this.clientDetailOrigin;
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    clientDetailOrigin: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    loading: false,
    items: ['Temporal', 'Plan'],
    client: {
      city: "",
      interiorNumber: "",
      lastNameMother: "",
      lastNamePather: "",
      movilNumber: "",
      name: "",
      outdoorNumber: "",
      street: "",
      suburb: "",
      userType: "Plan",
      tag: "",
      zip: "",
    },
    valid: true,
    requiredText: [(v) => !!v || "Campo requerido"],
    numberPhone: [
      (v) => !!v || "Campo requerido",
      (v) =>
        (v && v.toString().length == 10) ||
        "El número móvil se conforma de 10 números",
    ],
    zipValidation: [
      (v) => !!v || "Campo requerido",
      (v) =>
        (v && v.toString().length == 5) ||
        "El código postal se conforma de 5 números",
    ],
  }),
  methods: {
    stateManager() {
      if(this.isEdit) this.edit();
      if(!this.isEdit) this.create();
    },
    async create() {
      try {
        if (!this.$refs.form.validate()) return;

        await this.observerInteriorNumber();

        await Clients.create(this.client);

        this.$emit("changeComponent", "ClientsTable");
      } catch (error) {
        console.warn(error);
      }
    },
    async edit() {
      try {
        this.loading = true;
        await Clients.edit(this.client);
        this.$emit("changeComponent", "ClientsTable");
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    async observerInteriorNumber() {
      if (this.client.interiorNumber !== "")
        this.client.interiorNumber = parseInt(this.client.interiorNumber);

      if (this.client.interiorNumber === "") delete this.client.interiorNumber;
    },
    getTitle() {
      return this.isEdit ? "EDITAR DETALLES DEL CLIENTE" : "NUEVO CLIENTE";
    },
    getTextButton() {
      return this.isEdit ? "GUARDAR CAMBIOS" : "GUARDAR CLIENTE";
    },
  },
};
</script>

<style lang="scss" scoped>
.img--container {
  width: 8rem;
}
</style>